#sidebar {
  padding: 40px 0;
}

#sidebar .sticky {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: auto;
}

.sidebar-section, .sidebar-link {
  padding: 7px 0;
}

.sidebar-section {
  margin-top: 40px;
  font-weight: 600;
  color: var(--color2)
}

#sidebar .sidebar-section:first-child {
  margin-top: 0;
}

.sidebar-link {
  padding-left: 10px;
  color: var(--color3);
  border-left: 1px solid var(--border-color);
  margin-left: 4px;
}

.sidebar-link::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  position: relative;
  left: -13.5px;
  top: -3px;
}

.sidebar-link:hover {
  color: var(--color-hover);
  font-weight: 600;
  font-size: 98%;
}

.sidebar-link.current {
  color: var(--color-anchor);
  font-weight: 600;
  font-size: 98%;
}

.sidebar-link.current::before, .sidebar-link:hover::before {
  background: var(--color-anchor);
}
