#toc {
  padding-top: 40px;
  padding-bottom: 40px;
}

#toc .sticky{
  overflow: auto;
}

#toc strong {
  font-weight: 600;
  padding: 7px 10px 7px 0;
  display: flex;
  gap: 3px;
  position: relative;
  left: -3px;
  color: var(--color2)
}

#toc ul {
  margin-left: .3em;
  border-left: 1px solid var(--border-color);
}

#toc ul ul {
  margin-left: 1em;
}

#toc ul a {
  display: inline-block;
  padding: 7px;
  color: var(--color3);
}

#toc ul a.active, #toc ul a:hover {
  color: var(--color-hover);
}

#toc ul a::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background: var(--background);
  box-shadow: var(--box-shadow);
  position: relative;
  left: -10.5px;
  top: -3px;
}

#toc ul a.active::before, #toc ul a:hover::before {
  background: var(--color-hover);
}

