:root {
  --site-header-height: 46px;
  --site-footer-height: 46px;
}

@media (min-width: 1025px) and (max-width: 1280px),
(min-width: 1024px) and (max-width: 1280px) and (orientation: portrait) {
  :root {
    --site-header-height: 60px;
    --site-footer-height: 60px;
  }
}

@media (min-width: 1281px) {
  :root {
    --site-header-height: 80px;
    --site-footer-height: 80px;
  }
}