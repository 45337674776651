#site-footer-social {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  padding-left: 12px;
  align-items: center;
}

#site-footer-fund {
  display: flex;
  gap: 12px;
  overflow: auto;
  justify-content: flex-end;
  padding-right: 12px;
  align-items: center;
}

#site-footer-copyright, #site-footer-love {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color3)
}

#site-footer-copyright a {
  display: flex;
  align-items: center;
}

/* From Small Tablet */
@media (min-width: 768px) {
  #site-footer-copyright {
    justify-content: flex-start;
    padding-left: 12px;
  }

  #site-footer-social {
    justify-content: flex-end;
    padding-right: 12px;
  }
}
